// Dark theme
$dark-theme-primary-bg-col: #121B20;
$dark-theme-secondary-bg-col: #171F25;
$dark-theme-primary-font-col: #F5F5F5;
$dark-theme-secondary-font-col: #4F5B63;
$dark-theme-line-color: #2D343A;
$dark-theme-accent-col: #97DBFB;

// Light theme
$light-theme-primary-bg-col: #F8F8F8;
$light-theme-secondary-bg-col: #F0F1F2;
$light-theme-primary-font-col: #233b45;
$light-theme-secondary-font-col: #9FA6AB;
$light-theme-line-col: #D8DCE0;
$light-theme-accent-col: #36a977;

// Common
$warning-col: #CC5E57;
$blue: #40bbf4;
$red: #e4463b;

$header-height: 3.5rem;
$border-radius: .5rem;
$content-max-width: 1280px;

// Section
$section-gap: 3rem;
$section-padding: 5rem;

$icon-size: 1.25rem;