.contact-form {
    width: 80%;
    padding: 2rem;
    border-radius: .5rem;

    &__unsubmitted{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    &__input-container {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    &__label {
        text-transform: capitalize;
    }

    &__input {
        padding: .5rem;
        border-radius: .25rem;

        &:focus {
            outline: none;
        }

        &--message {
            height: 10rem;
            resize: vertical;
        }
    }

    &__submit-button {
        padding: .5rem;
        border-radius: .25rem;
        cursor: pointer;
    }

    &__submitted {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    &__submitted-icon {
        font-size: 3rem;
    }
}

@media screen and (max-width: 980px) {
    .contact-form {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .contact-form {
        padding: 1.5rem;
    }
}