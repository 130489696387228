@import "../../abstract/variables";

.edit-post-page {
    width: 90%;
    margin-top: 2rem;
    max-height: max-content;
    position: absolute;
    top: $header-height;
    display: flex;
    flex-direction: column;
    gap: 3rem;

}