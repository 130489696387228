@import "../../abstract/variables";

.post-page {
    width: 90%;
    margin-top: 2rem;
    max-height: max-content;
    position: absolute;
    top: $header-height;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    &__content {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    &__post-body {
        &--center {
            align-self: center;
        }
    }
}

@media screen and (max-width: 980px) {
    .post-page {
        &__content {
            width: 100%;
        }
    }
}