@import "../../abstract/mixins";
@import "../../abstract/variables";

.blog-page {
    @include position-page;

    width: 90%;
    margin-top: 2rem;
    max-height: max-content;
    position: absolute;
    top: $header-height;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    a:hover {
        text-decoration: underline;
    }

    input {
        width: 100%;
        padding: .5rem 0;
        border-radius: .25rem;
        font-family: "Inter", Arial, Helvetica, sans-serif;
        outline: none;
    }

    &__icon {
        font-size: 1rem;

        &--contact {
            font-size: 1.5rem;
        }
    }

    &__content {
        min-height: 75vh;
        height: max-content;
        display: flex;
        flex-direction: row;
        gap: 3rem;
    }

    &__side-menu-container {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    &__welcome-container,
    &__email-updates-container,
    &__contact-links-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__email-updates-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 .5rem;
        border-radius: .25rem;
    }

    &__contact-links {
        display: flex;
        flex-direction: row;
        gap: 1.75rem;
    }

    &__contact-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;
    }

    &__blog-feed-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__blog-feed {
        display: flex;
        flex-direction: column;
    }

    &__blog-feed-display-options {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
    }

    &__search-bar {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 .5rem;
        border-radius: .25rem;
    }

    &__sort-by-options-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}

@media screen and (max-width: 980px) {
    .blog-page {
        h3 {
            font-size: 1.5rem;
        }

        &__content {
            flex-direction: column;
            gap: 2rem;
        }

        &__side-menu-container,
        &__blog-feed-container {
            width: 100%;
        }

        &__side-menu-container {
            gap: 2rem;
            padding-bottom: 2rem;
        }

        &__welcome-container,
        &__email-updates-container,
        &__contact-links-container {
            display: flex;
            flex-direction: column;
            gap: .75rem;
        }
    } 
}