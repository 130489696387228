@import "../../scss/abstract/variables";

.login-page {
    width: 100%;
    height: max-content;
    position: absolute;
    top: 3.5rem;
    padding: 5rem;
    
    &__login-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__login-container {
        width: 20rem;
        max-width: 50%;
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        border-radius: .25rem;
    }

    &__login-heading {
        font-size: 2rem;
    }

    &__input-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__input-group {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    &__input {
        padding: .5rem;
        font-size: 1rem;
        font-family: 'Inter', Arial, Helvetica, sans-serif;
        border-radius: .25rem;

        &:focus {
            outline: none;
        }
    }

    &__submit-button {
        width: 100%;
        padding: .75rem .5rem;
        border: none;
        border-radius: .25rem;
        cursor: pointer;
    }
}