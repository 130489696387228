@import "../abstract/variables";

.text-editor {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    textarea {
        height: 25vh;
        resize: vertical;
    }

    &__text-input {
        padding: .5rem;
    }

    &__options-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__action-buttons-container {
        display: flex;
        gap: 1rem;
    }

    &__action-button {
        padding: .5rem 1rem;
        border-radius: .25rem;
        outline: none;
        cursor: pointer;
        transition: .2s background-color ease-in-out;
    }
}