@import "../abstract/variables";

.ThemeToggler {
    padding: .5rem;
    font-size: $icon-size;
    background-color: transparent;
    border: none;
    border-radius: .25rem;
    cursor: pointer;
    transition: color .25s, background-color .25s;
}